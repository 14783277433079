/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  $.fn.extend({
    toggleClass: function(c) {
        if($(this).hasClass(c)) {
            $(this).removeClass(c);
        }

        else {
            $(this).addClass(c);
        }
    },
  });
})(jQuery);


(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $.viewportWidth = function() {
            return window.innerWidth;
        };

        window.breakpoints = (function() {
            var breakpoints = JSON.parse($(".js-breakpoints-data").text());

            Object.keys(breakpoints).forEach(function(key) {
                // these are defined in ems, so multiply to get
                // the pixel value
                breakpoints[key] = parseInt(breakpoints[key].min_width) * 16;
            });

            return breakpoints;

        })();

        console.log("Registered the following breakpoints:");

        Object.keys(window.breakpoints).forEach(function(key) {
            console.log("\t" + key + ": " + window.breakpoints[key]);
        });

        $(".js-matchheight").matchHeight();
        $(".js-dropdown-height").matchHeight();
        (function() {
            var $matchAlways = $(".js-matchheight-always");
            var matchHeight  = function() {
                var maxHeight = 0;

                $matchAlways.each(function() {
                    maxHeight = maxHeight > $(this).outerHeight() ? maxHeight : $(this).outerHeight();

                    console.log($(this).outerHeight());
                });

                $matchAlways.each(function() {
                    $(this).height(maxHeight);
                });
            };

            $(window).resize(matchHeight);

            $(window).load(matchHeight);
            console.log('running');
        })();

        (function() {

            var $contactFormError = $(".contact-form .validation_error");
            var scrollToForm  = function() {

                $contactFormError.each(function() {
                    $("html, body").animate({
                        scrollTop: $contactFormError.offset().top,
                    }, function() {
                        $elem.find('.validation_error').focus();
                    });

                });

            };
            $(window).load(scrollToForm);
        })();

        $(".js-copy-button").each(function() {
            $(this).click(function() {
                $(".js-copy-text").select();
                document.execCommand('copy');
                var $elm = $("<div class='absolute left top bg-gray-2 p1 fs-13'>copied to clipboard<div>");
                $elm.appendTo($(".js-copy-alert")).show('slow');
                setTimeout(function(){
                    $elm.remove();
                }, 5000)
            })
        });

        $("label").each(function() {
            var content = $(this).text();

            if(/^\s+$/.test(content)) {
                $(this).remove();
            }
        });

        $(".js-fade-in-child").each(function() {
            var $child = $(this).find(".js-fade-in-this");

            $(this).hover(function() {
                $child.css({
                    display: 'block',
                });
            }, function() {
                $child.css({
                    display: 'none',
                });
            });
        });

        (function() {
            var $form = $(".js-search-form");
            var $formButton = $(".js-search-form-button");
            var $inputWrapper = $(".js-search-form-field-wrapper");
            var $input = $(".js-search-form-input");

            var hidden = true;

            $formButton.click(function() {
                if(hidden) {
                    $inputWrapper
                        .transition({ 'max-width': 0, display: 'block' }, function() {
                            var inputWidth = $input.width();

                            $inputWrapper.transition({
                                'max-width': inputWidth + 8 + 'px',
                                'padding-left': '4px',
                                'padding-right': '4px',
                            }, 500, function() {
                                $input.focus();
                                hidden = !hidden;
                            });
                        });
                }

                else {
                    var content = $input.val();

                    if(content) {
                        $form.submit();
                    }

                    else {
                        $inputWrapper.transition({
                            'max-width': 0,
                            'padding-left': 0,
                            'padding-right': 0,
                        }, 500, function() {
                            hidden = !hidden;
                        });
                    }
                }
            });
        })();

        $(".js-dropdown-menu").each(function() {
    		var fading = false, $dropdown = $(this), $parent = $(this).parent();
    		var leave = function() {
    			if(!fading) {
    				fading = true;

    				$dropdown.fadeOut({ done: function() {
    					$dropdown.css({ display: 'none' });

    					setTimeout(function() {

    						fading = false;

    					}, 200);

    				} });
    			}
    		};

    		$parent.hoverIntent(function() {
                $(".js-dropdown-height").matchHeight();

    			if(!fading) {
    				$dropdown.fadeIn(100);
    			}
    		})

    		$parent.mouseleave(leave);

    	});


        $(".js-toggle-dropdown").each(function() {
            var index = $(this).data('index');
            var toggleThat = $(this).data('toggle-that');
            var toggleThis = $(this).data('toggle-this');
            var $products = $(".js-dropdown-target");

            var $target = $(".js-dropdown-target[data-index=" + index + "]");

            $(this).hover(function(ev) {
                $products.not($target).removeClass('md-block');
                $(".js-toggle-dropdown").not(this).parent().removeClass('dropdown-selected');
                $(".js-toggle-background").removeClass('bg-gray-3');
                if( $target.data('index') == index ) {
                    if($target.length >= 1) {
                        $(".js-toggle-background").addClass('bg-gray-3');
                    } else {
                        $(".js-toggle-background").removeClass('bg-gray-3');
                    }
                    $target.addClass(toggleThat);
                    $(this).parent().addClass('dropdown-selected');
                    $('.js-dropdown-height').matchHeight();
                }
            });
        });


        $(".js-toggle-teaser").each(function(){
            var index = $(this).data('index');
            var type = $(this).data('type');
            var toggleThis = $(this).data('toggle-this');
            var $target = $(".js-teaser-target-"+ type + "-" + index + "");
            $(this).click(function(ev) {
                ev.preventDefault();
                console.log($target);
                $target.toggleClass(toggleThis);
            });
        });

        $(".js-toggleable").each(function() {
            var index = $(this).data('index');
            var toggleThat = $(this).data('toggle-that');
            var toggleThis = $(this).data('toggle-this');
            var $target = null;

            if($(this).data('target')) {
                $target = $($(this).data('target'));
            }

            else {
                $target = $(".js-toggleable-target[data-index=" + index + "]");
            }

            $(this).click(function(ev) {
                if( $.viewportWidth() < window.breakpoints.lg ) {
                    $(this).toggleClass(toggleThis);
                    $target.toggleClass(toggleThat);
                }
            });
        });

        $(".js-toggle-tab").each(function() {
            var thisClassName = $(this).data('toggle-this');
            var thatClassName = $(this).data('toggle-that');
            var $target = $($(this).data('target'));

            $(this).click(function() {
                $('.' + thisClassName).not(this).removeClass(thisClassName);
                $('.' + thisClassName).not(this).toggleClass('block');

                if (!$(this).hasClass(thisClassName)) {
                    $(this).toggleClass(thisClassName);
                }

                $($(this).data('all')).addClass(thatClassName);
                $target.removeClass(thatClassName);
            });
        });

        $(".js-show-form").each(function(){
            $(".js-show-form .gfield_radio input").each(function(){
                if($(this).val() === 'No' && $(this).is(':checked')){
                  $('.more-gform-section').show();
                  $('.shop-drawing-form .gform_footer').show();
                  $('.shop-drawing-form .hide-at-first').hide();
                }
            });
            $(".js-show-form .gfield_radio input").on('change', function(){
                if($(this).val() === "Yes"){
                    $('.more-gform-section').hide();
                    $('.shop-drawing-form .gform_footer').hide();
                    $('.shop-drawing-form .hide-at-first').show();
                }

                if($(this).val() === "No"){
                    $('.more-gform-section').show();
                    $('.shop-drawing-form .gform_footer').show();
                    $('.shop-drawing-form .hide-at-first').hide();
                }
            });
        });

        (function() {
            var tabCheck = function(){
                $(".js-product-tab").each(function(){
                    var thisClassName = $(this).data('toggle-this');
                    var slugify = function(tabName){
                        return $.trim(tabName).toLowerCase().replace(' ', '-');
                    };

                    $(this).click(function() {
                        $('.product-tab').not(this).toggleClass('product-tab--expand');
                        var tabName = slugify($(this).text());
                        window.location.hash = tabName;
                    });

                    if(slugify($(this).text()) === window.location.hash.replace('#', '')){
                        $(this).click();
                    }

                });
            };

            tabCheck();
            window.onhashchange = tabCheck;

            var $former   = $(".js-former-item");

            var $products   = $(".js-products");

            var $holder  = $("<div></div>").css({ display: 'none' }).appendTo($("body"));
            var $visible = $(".js-former-visible");

            var products   = {};

            var pushUnique = function(a, item) {
                if(-1 === a.indexOf(item)) {
                    a.push(item);
                }

                return a;
            };

            var filter = function() {
                var product  = parseInt($products.val());

                $former.each(function() {
                    var keep = true;

                    $(this).appendTo($holder);

                    if( keep && product !== -1 ) {
                        keep = false;

                        $(this).data('products').forEach(function(p) {
                            if(p.id === product) {
                                keep = true;
                            }
                        });
                    }

                    if(keep) {
                        $(this).appendTo($visible);
                    }
                });
            };

            $former.each(function() {
                var theseProducts = $(this).data('products');

                theseProducts.forEach(function(p) {
                    products[p.id] = p.name;
                });
            });

            Object.keys(products).forEach(function(key){
                console.log(products[key]);
                $products.append('<option value="'+ key +'">'+ products[key] +'</option>');
            });

            [ $products ].forEach(function($item) {
                $item.change(filter);
            });

        })();

        (function() {
            var textNodes = [];
            var n = null;
            var walker = document.createTreeWalker($("body").get(0), NodeFilter.SHOW_TEXT, null, false);
            while(n = walker.nextNode()) textNodes.push(n);

            textNodes.forEach(function(n) {
                var tmpElement = document.createElement("div");
                var newValue;
                var nodes = [];
                var reg = /([0-9]+)\/([0-9]+)/g;
                if(!['SCRIPT', 'STYLE'].indexOf(n.parentNode.tagName) && reg.test(n.nodeValue)) {
                    n.parentNode.innerHTML = n.parentNode.innerHTML.replace(reg, '<sup>$1</sup>/<sub>$2</sub>');
                }
            });
        })();
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(window).trigger('finalize');
        $('.js-matchheight').matchHeight();
      }
    },
    // Home page
    'page_template_template_home': {
      init: function() {
        // JavaScript to be fired on the home page
        var slickActive = $.viewportWidth() >= window.breakpoints.md;

        var slideHelper = function(event, slick, currentSlide, nextSlide) {
            var $allSlides = slick.$slides.parent().find(".slick-slide");
            var index = null;
            var i;
            var slideDiff = nextSlide - currentSlide;
            var forward = (slideDiff > 0 && Math.abs(slideDiff) == 1) || (slideDiff < 0 && Math.abs(slideDiff) != 1) ;
            var nonCloneStart, nonCloneEnd;

            function findFirstMatching(fn, start) {
                for(i = start; i < $allSlides.length; i++) {
                    if(fn($($allSlides.get(i)))) {
                        return i;
                    }
                }

                return false;
            }

            var index = findFirstMatching(function($item) {
                return $item.hasClass('slick-current');
            }, 0);

            var nonCloneStart = findFirstMatching(function($item) {
                return !$item.hasClass('slick-cloned');
            }, 0);

            var nonCloneEnd = findFirstMatching(function($item) {
                return $item.hasClass('slick-cloned');
            }, nonCloneStart + 1);

            function circularGet(n) {
                var $slide;

                if(n < 0) {
                    n = $allSlides.length - n;
                }

                $slide = $($allSlides.get((n >= $allSlides.length) ? n - $allSlides.length : n));

                return $slide;
            };

            function circularGetRange(n, length) {
                var items = [];

                for(i = n; i < length; i++) {
                    items.push(circularGet(i));
                }

                return items;
            }

            var prep = function(leftIndex) {
                var $leftSlide  = circularGet(leftIndex);
                var $rightSlide = circularGet(leftIndex + 5);

                var resetTheseSlides = [
                    circularGet(leftIndex + 1),
                    circularGet(leftIndex + 4),
                ];

                $rightSlide.removeClass('product-slider__slide--left');
                $rightSlide.addClass('product-slider__slide--right');

                $leftSlide.removeClass('product-slider__slide--right');
                $leftSlide.addClass('product-slider__slide--left');

                resetTheseSlides.forEach(function($s) {
                    $s.removeClass('product-slider__slide--left').removeClass('product-slider__slide--right');
                });
            };

            var leftIndex = index + (forward ? 1 : -1);

            if(currentSlide == nextSlide) {
                return;
            }

            prep(leftIndex);

            if(forward && nonCloneEnd - 1 == leftIndex) {
                prep(nonCloneStart);
            }

            else if((!forward) && (nonCloneStart - 1) == leftIndex) {
                prep(nonCloneEnd - 1);
            }
        };

        var startSlick = function() {
            $slider = $(".product-slider__widener");

            $slider
                .slick({
                    slidesToShow: 6,
                    autoplay: false,
                    draggable: true,
                })
                .on('beforeChange', slideHelper)
                .slick('slickNext');

            $slider.find('.slick-slide').each(function() {
                $(this).on('click', function() {
                    console.log('click');
                    if($(this).hasClass("product-slider__slide--left")) {
                        $slider.slick('slickPrev');
                    }

                    else if ($(this).hasClass("product-slider__slide--right")) {
                        $slider.slick('slickNext');
                        console.log('next');
                    }

                    $('.product-slider__slide--left a').on('click', function(e) {
                        e.preventDefault();
                    });

                    $('.product-slider__slide--right a').on('click', function(e) {
                        e.preventDefault();
                    });

                });
            });
        };

        if(slickActive) {
            startSlick();
        }

        $(window).resize(function() {
            if(slickActive && ($.viewportWidth() < window.breakpoints.md)) {
                $(".product-slider__widener")
                    .find(".product-slider__slide")
                    .each(function() {
                        $(this)
                            .removeClass("product-slider__slide--left")
                            .removeClass("product-slider__slide--right");
                    });

                $(".product-slider__widener").slick("unslick");

                slickActive = !slickActive;
            }

            else if((!slickActive) && $.viewportWidth() >= window.breakpoints.md) {
                startSlick();
                slickActive = !slickActive;
            }
        });

        $(".js-hero-slider").slick({
            dots: true,
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 7000,
            adaptiveHeight: true,
        });

        $(".js-project-gallery").slick({
            dots: true,
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 7000,
            asNavFor: $(".js-project-gallery-below"),
        });

        $(".project-gallery__project").matchHeight();

        $('.js-project-gallery').each(function() {
            var _ = this;
            var explicitHeight = function() {
                $(_).css({
                    height: ""
                });

                $(_).height($(_).height());
            };
        });

        $(".js-project-gallery-below").slick({
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: true,
            autoplaySpeed: 99999999,
            asNavFor: $(".js-project-gallery"),
        });

        $('.product-slider__slide--left a').on('click', function(e) {
            e.preventDefault();
        });

        $('.product-slider__slide--right a').on('click', function(e) {
            e.preventDefault();
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_resource': {
      init: function() {
        // JavaScript to be fired on the single resource page
        $('.chart-of-sri-values').each(function(){
            $('.collection-table__row').each(function(){
                var colorName = $(this).find("[data-label='Berridge Colors']").text();
                if($.trim(colorName) == 'Premium Colors' || $.trim(colorName) == 'Metallic Colors') {
                    $(this).addClass('collection-table__row--top');
                }
            });
        });
      }
    },
    'post_type_archive_licensee': {
      init: function() {
          var $states = $(".js-states");
          var $licensee = $(".js-licensee");
          var $holder  = $("<div class=''></div>").css({ display: 'none' }).appendTo($("body"));
          var $visible = $(".js-licensee-visible");

          var states = [];

          var base = $(".js-states").data('archive-base');

          console.log(base);

          var pushUnique = function(a, item) {
              if(-1 === a.indexOf(item)) {
                  a.push(item);
              }

              return a;
          };

          $states.change(function() {
            window.location.href = base + (('-1' != $states.val()) ? ('?state=' + $states.val()) : "");
          });
      }
    },

    'single_product': {
      init: function() {

        var $gallery = $(".js-gallery-data");
        var $circles = $(".swatches__circle");

        var $product_nav_items = $(".product-nav__item");
        var $product_slider    = $(".js-product-slider");

        var set_nav_item = function(index) {
            $product_nav_items.removeClass("product-nav__item--active");
            $($product_nav_items.get(index)).addClass("product-nav__item--active");
        };

        $product_slider.slick({
            arrows: false
        });

        $product_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            set_nav_item(nextSlide);

        });

        $product_slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
            if($('.slick-current').attr('data-slick-index') == 0) {
                $('.swatches').show();
            } else {
                $('.swatches').hide();
            }
        });
        $product_nav_items.each(function() {
            var index = $(this).data('index');

            $(this).click(function() {
                set_nav_item($(this).data('index'));

                $product_slider.slick('slickGoTo', index);
            });
        });


        $(".swatches__just-one").click(function() {
            $(".swatches").toggleClass("swatches--collapsed");
        });

        $circles.each(function() {
            var color = $(this).data('color');
            var texture = $(this).data('texture');
            var disabled = $(this).data('disabled');

            $(this).not('.js-color').click(function() {
                if (!disabled) {
                    $circles.each(function() {
                        $(this).removeClass('swatches__circle--active');
                    });

                    $(this).addClass('swatches__circle--active');

                    if (!color && texture) {
                        // Apply texture as the background image and clear any background color.
                        $(".js-color").transition({
                            'background-image': 'url(' + texture + ')'
                        });
                        $(".js-color").css('background-color', '');
                    } else {
                        // Apply the background color and remove any background image.
                        $(".js-color").transition({
                            'background-color': color,
                        });
                        $(".js-color").css('background-image', 'none');
                    }

                    $(".swatches").addClass("swatches--collapsed");
                }
            });
        });


		var $win = $(window); // or $box parent container
		var $box = $(".swatches");

		$win.on("click.Bst", function(event){
			if (
                $box.has(event.target).length == 0 //checks if descendants of $box was clicked
                &&
                !$box.is(event.target) //checks if the $box itself was clicked
            ){
                $(".swatches").addClass("swatches--collapsed");
			}
		});


        $gallery.length > 0 && (function() {
          var parsed = JSON.parse($gallery.text());
          var images = parsed.html;
          var preloadData = parsed.preload;
          var preloadRatio = null;

          if(window.devicePixelRatio && window.devicePixelRatio > 1.5) {
            preloadRatio = 2;
          }

          else {
            preloadRatio = 1;
          }

          $(".js-magnific-popup").each(function() {
            var id = $(this).data('gallery-id');

            $(this).magnificPopup({
              items: images,

              gallery: {
                enabled: true,
              },

              index: id,

              callbacks: {
                change: function() {
                  var id = $(this.content).data('gallery-id');
                  var prev = preloadData[id != 0 ? id - 1 : preloadData.length - 1];
                  var next = preloadData[id != preloadData.length - 1 ? id : 0];

                  var nextImg = new Image();
                  var prevImg = new Image();

                  nextImg.src = next['x' + preloadRatio];
                  prevImg.src = prev['x' + preloadRatio];
                },
              },
            });
          });
        })();
      }
    },

    'page_template_template_project_galleries': {
        init: function() {
            var $projects   = $(".js-gallery-item");

            var $colors     = $(".js-colors");
            var $industries = $(".js-industries");
            var $products   = $(".js-products");

            var $holder  = $("<div></div>").css({ display: 'none' }).appendTo($("body"));
            var $visible = $(".js-project-visible");

            var industries = [];
            var products   = {};
            var colors     = {};

            var pushUnique = function(a, item) {
                if(-1 === a.indexOf(item)) {
                    a.push(item);
                }

                return a;
            };

            var filter = function() {
                var color    = parseInt($colors.val());
                var industry = $industries.val();
                var product  = parseInt($products.val());

                $projects.each(function() {
                    var keep = true;

                    $(this).appendTo($holder);

                    if( color !== -1 ) {

                        keep = false;

                        $(this).data('products').forEach(function(p){
                            p.colors.forEach(function(c) {
                                if( c.id == color ) {
                                    keep = true;
                                }
                            });
                        });
                    }

                    if( keep && industry != -1 && industry != $(this).data('industry') ) {
                        keep = false;
                    }

                    if( keep && product !== -1 ) {
                        keep = false;

                        $(this).data('products').forEach(function(p) {
                            if(p.id === product) {
                                keep = true;
                            }
                        });
                    }

                    if(keep) {
                        $(this).appendTo($visible);
                    }
                });
            };

            $projects.each(function() {
                var industry = $(this).data('industry');
                var theseProducts = $(this).data('products');

                theseProducts.forEach(function(p) {
                    p.colors.forEach(function(c) {
                        colors[c.id] = c.name;
                    });

                    products[p.id] = p.name;
                });

                industries = pushUnique(industries, industry);

            });

            industries.forEach(function(i){
                if (i) {
                    $industries.append('<option value="'+ i +'">'+ i +'</option>');
                }
            });

            Object.keys(products).forEach(function(key){
                $products.append('<option value="'+ key +'">'+ products[key] +'</option>');
            });

            Object.keys(colors).forEach(function(key){
                $colors.append('<option value="'+ key +'">'+ colors[key] +'</option>');
            });

            [ $colors, $products, $industries ].forEach(function($item) {
                $item.change(filter);
            });
        },
    },

    'single_project': {
        init: function() {
            $(".js-project-gallery").slick({
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 7000,
            });

            var arrowsFor = function(className) {

                return function() {

                    var direction = $(this).data('direction');
                    var $slider   = $(className);

                    var opts = {
                        'right': 'slickNext',
                        'left': 'slickPrev',
                    }, res = opts[direction];

                    if(!res) {
                        throw "Invalid slider option.";
                    }

                    $(this).click(function() {
                        $slider.slick(res);
                    });
                }
            };

            $(".js-project-arrow").each(arrowsFor(".js-project-gallery"));
        },
    },

    'page_template_template_order_form': {
        init: function() {
            var MODE_DEFAULT = 1, MODE_PRODUCT = 2, MODE_COLORS = 3, mode = MODE_DEFAULT;
            var isProductActive = false, isColorActive = false, isLitActive = false;
            var formTools = $.getFormTools();

            var form = new formTools.Form();

            var clearButtons = function() {
                $(".js-color-button, .js-product-button")
                    .children()
                    .removeClass('border-jungle-green')
                    .css({ 'border-width': '' });
            };

            var lockForm = true;

            $('.js-color-button').click(function() {
                if(!isColorActive) {
                    var colorBox;

                    form.$addMore.css({ display: 'none' });

                    $(this).children().addClass('border-jungle-green').css({ 'border-width': '2px' });

                    colorBox = new formTools.ColorBox(form);

                    colorBox.$box.find('.js-cancel').remove();

                    isColorActive = true;

                } else {
                    $(this)
                        .children()
                        .removeClass('border-jungle-green')
                        .css({ 'border-width': '' });
                    isColorActive = false;
                    if(form.colors) {
                        form.colors.remove();
                    }
                }

            });

            $(".js-product-button").click(function() {

                if(!isProductActive) {
                    var productBox;

                    $(this).children().addClass('border-jungle-green').css({ 'border-width': '2px' });

                    productBox = new formTools.ProductBox(form);

                    productBox.$box.find('.js-cancel').remove();

                    isProductActive = true;

                } else {
                    $(this)
                        .children()
                        .removeClass('border-jungle-green')
                        .css({ 'border-width': '' });
                    isProductActive = false;
                    while(form.products.length > 0) {
                        form.products[0].remove();
                    }
                }

            });

            $(".js-lit-button").click(function() {

                if(!isLitActive) {
                    var litBox;

                    $(this).children().addClass('border-jungle-green').css({ 'border-width': '2px' });

                    litBox = new formTools.LitBox(form);
                    // litBox.$box.find('.js-cancel').remove();
                    //
                    isLitActive = true;

                } else {
                    $(this)
                        .children()
                        .removeClass('border-jungle-green')
                        .css({ 'border-width': '' });
                    isLitActive = false;
                    form.literature.remove();
                }

            });

            form.$form.submit(function(ev) {
                console.log('submitting!');

                if(!lockForm) {
                    return;
                }

                ev.preventDefault();

                var serialized = form.$form.serializeArray();
                var asObject   = {};
                var asString;

                var products   = [];
                var colors     = null;
                var literature     = null;

                var hasScrolled = false;
                var isShippingOkay = true;

                var isGood = true;

                var addErrorMsg = function($elem, msg) {
                    var $tmp = $("<div></div>").css({ display: 'none' }).appendTo($("body"));

                    if(0 == $elem.find('.js-error-notice').length) {
                        $elem.children().appendTo($tmp);

                        $elem.append("<div class='border border-err border-width-2'>"
                            + "<span class='block bg-err white p1 mb2 fs-13 font-main'>"
                                + msg
                            + "</span>"
                            + "<div class='js-error-notice'></div>"
                        + "</div>");

                        $tmp.children().appendTo($elem.find('.js-error-notice'));
                    }

                    if(!hasScrolled) {
                        hasScrolled = true;

                        $("html, body").animate({
                            scrollTop: $elem.find('.js-error-notice').position().top,
                        }, function() {
                            $elem.find('.js-error-notice').focus();
                        });
                    }

                    isGood = false;
                };

                form.products.forEach(function(p) {
                    var isValid = p.validate()

                    if(isValid) {
                        products.push(p.getValidData());
                    }

                    else {
                        addErrorMsg(p.$box, 'Make sure to select a product and some colors or the default color.');
                    }
                });

                if(form.colors) {
                    if(form.colors.validate()) {
                        colors = form.colors.getValidData();
                    }

                    else {
                        addErrorMsg(form.colors.$box, 'You need to select some colors!');
                    }
                }

                if(form.literature) {
                    if(form.literature.validate()) {
                        literature = form.literature.getValidData();
                    }
                }

                isShippingOkay = form.shippingInfo.validate();

                if(!isShippingOkay) {
                    addErrorMsg($(".js-shipping-info"), "Make sure to fill out the shipping fields.");
                }

                if(isGood) {
                    serialized.forEach(function(item) {
                        asObject[item.name] = item.value;
                    });

                    asObject.products = products;
                    asObject.colors   = colors;
                    asObject.literature   = literature;

                    asString = JSON.stringify(asObject);

                    form.$gravityForm.$field.val(asString);
                    form.$gravityForm.$emailField.val(asObject.address_email);

                    form.$gravityForm.find("form").submit();
                }

            });
        },
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
